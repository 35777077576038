<template>
    <div class="pagebox">

        <!-- 顶部 -->
        <PHeader></PHeader>

        <!-- 公告内容 -->
        <div class="noticediv">
            <div class="noticecon">
                <div class="tit">{{newsdata.Title}}</div>
                <div class="txt Class_RichTextContent" v-html="newsdata.Content">
                </div>
            </div>
        </div>

        <!-- 底部 -->
        <PFooter></PFooter>

    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
import API from "@/api/config";
export default{
    components:{
        PHeader,
        PFooter
    },
    data(){
        return{
            newsdata:{}
        }
    },
    mounted(){
        API.Web.UpgradeNewsDetail({Id:this.$route.query.id+''}).then(res => {
            if(res.Code===0){
                this.newsdata = res.Data;
            }
        });
    }
}
</script>
<style lang="less" scoped>
.noticediv{
    max-width: 1200px;
    margin: auto;
    .noticecon{
        margin: 30px 20px 30px;
        border: 1px solid #ddd;
        min-height: 600px;
        background-color: #fff;
        .tit{
            color: #444;
            font-size: 20px;
            line-height: 30px;
            margin: 20px 20px 0;
            text-align: center;
            font-weight: bold;
            border-bottom: 1px solid #ddd;
            padding-bottom: 20px;
        }
        .txt{
            color: #555;
            font-size: 14px;
            line-height: 24px;
            text-indent: 28px;
            margin: 20px 30px 30px;
            overflow: hidden;
        }
    }
}
</style>
<style lang="less">
.Class_RichTextContent img,.Class_RichTextContent video{
    width: auto;
    max-width: 100%;
    display: block;
}
</style>